import Case from "case";
import merge from "lodash/merge";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useMediaQuery } from "react-responsive";

import Button from "components/Button";
import { MerchantType } from "components/Cart/interface";
import InputNumber from "components/InputNumber";
import Toast from "components/Toast";
import { useAnalytics } from "hooks/useAnalytics";
import useCart from "hooks/useCart";
import { useFirebaseAuth } from "hooks/useFirebaseAuth";
import api from "services/api";
import { Modal } from "../Modal";
import Description from "./Description";
import ItemPrice from "./ItemPrice";
import Modifier from "./Modifier";
import { Item, ItemDetailProps } from "./interface";

import * as B from "../ButtonStyle/styles";
import * as S from "./styles";
import { formatCurrencyWithSymbol } from "utils/formats/number";

const MerchantLoader = () => (
  <ContentLoader
    height={30}
    speed={1}
    foregroundColor={"#e6e6e6"}
    backgroundColor={"#dfdfdf"}
    viewBox="0 0 280 40"
  >
    <rect x="0" y="8" rx="4" ry="4" width="100%" height="25" />
  </ContentLoader>
);

const moneyFormatter = (money: number) => "$" + (money / 100).toFixed(2);

const replaceText = (text: string | undefined) => {
  let newText = text || "";

  newText = newText.replaceAll(/(\\n+[^.]+\\n)/g, "<b>$1</b>");

  newText = newText.replaceAll(
    /\((\$[^)]+)\)/g,
    "<span style='float:right; font-weight: bold'>$1</span>"
  );

  newText = newText.replaceAll("\n\n", "<br /><br />");

  return newText;
};

const ItemDetail = ({
  itemProp,
  optionsProp,
  close,
  editing = false,
  find = false,
  merchantData,
}: ItemDetailProps) => {
  const [item, setItem] = useState<Item>(itemProp);
  const [options, setOptions] = useState(optionsProp);
  const [isFinding, setIsFinding] = useState<boolean>(find);
  const [total, setTotal] = useState<number>(item.price);
  const [subtotal, setSubtotal] = useState<number[]>([]);
  const [isValidModifiers, setIsValidModifiers] = useState<boolean[]>([]);
  const [qty, setQuantity] = useState<number>(item.qty || 1);
  const [description, setDescription] = useState<string>();
  const { addToCart, addToCartWithNewMerchant, cart, toggleOpen, fetchItems } =
    useCart();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [merchant, setMerchant] = useState<MerchantType | null>(
    merchantData || null
  );
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const { authUser } = useFirebaseAuth();
  const { logAddToCart, logViewItem } = useAnalytics();
  const router = useRouter();

  const merchantId = merchant?.id || (item.merchant as string);
  const isBentoPackage = merchantId == "aMpH5VC0j6iWA9hRLbdR";

  useEffect(() => {
    console.log("is finding", find);
    if (isFinding) {
      getItemDetail();
    }

    handleItemDetail();

    logViewItem({
      itemCategory: item.categories,
      itemId: item.itemId,
      itemName: item.name,
      itemValue: item.price,
      merchantId: item.merchant,
    });
  }, []);

  const getMerchantMenu = async (id: string) => {
    await api
      .get("/merchants/" + id + "/menu")
      .then((response) => {
        console.log("set options");
        setOptions(response.data.options);
      })
      .finally(() => {
        setIsFinding(false);
      });
  };

  useEffect(() => {
    if (!isFinding) handleItemDetail();
  }, [isFinding]);

  const handleItemDetail = () => {
    setDescription(item.comments);
    if (typeof item.merchant === "string" && !merchantData) {
      getMerchant(item.merchant);
    }

    if (item.modifiers !== undefined) {
      setupSubtotal();
      setupRequiredModifiers();
    }

    if (item.price !== undefined) {
      handleTotal();
    }
  };

  const getItemDetail = async () => {
    try {
      const { data } = await api.get(`/items/${item.itemId}`);

      const newItem = merge(itemProp, data);

      setItem(newItem);

      if (!optionsProp) {
        getMerchantMenu(newItem.merchant);
      }
    } finally {
    }
  };

  const setupSubtotal = () => {
    let emptySubtotal: number[] = [];
    emptySubtotal = item.modifiers.map((modifier) => {
      return modifier.options.reduce((sum, option) => {
        if (option.qty > 0) return sum + option.qty * option.price;
        return sum;
      }, 0);
    });

    setSubtotal(emptySubtotal);
  };

  const setupRequiredModifiers = () => {
    const validModifiers = item.modifiers.map((modifier) => {
      const isRequired = modifier.min > 0;
      if (isRequired) {
        const someOptionSelected = modifier.options.some((item) => {
          return item.qty >= modifier.min;
        });
        return someOptionSelected;
      } else {
        return !isRequired;
      }
    });

    setIsValidModifiers(validModifiers);
  };

  useEffect(() => {
    handleTotal();
  }, [qty]);

  const setOptionsNames = () => {
    if (item.modifiers) {
      item.modifiers.map((modifier) => {
        modifier.options = modifier.options.map((option) => {
          const optionFind = options?.find((optionFind) => {
            return optionFind.itemId === option.itemId;
          });

          if (optionFind) option.name = optionFind.name;

          return option;
        });

        return modifier;
      });
    } else {
      item.modifiers = [];
    }
  };

  const handleTotal = () => {
    let sumSubtotal = 0;

    for (let i = 0; i < subtotal.length; i++) {
      sumSubtotal += subtotal[i];
    }

    const discountPrice =
      item.prices?.length > 0
        ? Math.min(...item.prices.map((p) => p.price))
        : 0;
    const hasDiscountPrice = discountPrice > 0 && discountPrice < item.price;
    const newPrice = hasDiscountPrice ? discountPrice : item.price;

    let total = (newPrice + sumSubtotal) * qty;
    if (isNaN(total)) total = newPrice;

    setTotal(total);
  };

  const onChangeQuantity = (value: number) => {
    setQuantity(value);
  };

  const onChangeModifier = (items: Item[], index: number, isValid: boolean) => {
    let currentSubtotal = 0;
    items.map((item: Item) => {
      if (item.qty) currentSubtotal += item.qty * item.price;
    });

    const newSubtotal = subtotal;
    newSubtotal[index] = currentSubtotal;

    setSubtotal(newSubtotal);
    handleTotal();

    setIsValidModifiers(
      isValidModifiers.map((item, i) => {
        if (i === index) return isValid;
        return item;
      })
    );
  };

  const addItemToCart = () => {
    item.qty = qty;
    item.comments = description;

    const merchantId = merchant?.id || (item.merchant as string);

    setOptionsNames();

    if (cart.items.length === 0) {
      handleAddItem();
      return;
    }

    if (isSameMerchant(merchantId)) {
      if (!editing && isBentoPackage && cart.items.length > 0) {
        Toast({
          title: "Only 1 item",
          message: "Only one item is allowed for Bento Package orders",
        });
        return;
      }

      setIsLoading(true);
      addItemToApiCart(item);
    } else {
      setIsOpenConfirm(true);
    }
  };

  const isSameMerchant = (merchantId: string) => {
    return merchantId === cart.merchant?.id;
  };

  const onChangeDescription = (e: any) => {
    setDescription(e.target.value);
  };

  const isValidItem = () => {
    return isValidModifiers.some((validModifier) => validModifier === false);
  };

  const onClose = () => {
    setIsOpenConfirm(false);
  };

  const getMerchant = async (merchantId: string) => {
    try {
      const { data: merchant } = await api.get(`/merchants/${merchantId}`);
      setMerchant({
        id: merchant.id,
        name: merchant.name,
        logo: merchant.logo,
        slug: merchant.slug,
        coordinates: merchant.address.coordinates,
        servicesAvailable: merchant.servicesAvailable,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddItem = useCallback(async () => {
    if (authUser) {
      setIsLoading(true);

      const data = {
        ...item,
        merchant: merchant?.id || (item.merchant as MerchantType).id,
      };
      api
        .post(`/users/${authUser.uid}/cart/add-item`, data)
        .then((response) => {
          setIsLoading(false);
          addToCartWithNewMerchant(item);

          logAddToCart({
            itemCategory: item.categories,
            itemId: item.itemId,
            itemName: item.name,
            merchantId: item.merchant,
            quantity: item.qty,
          });

          close();
          !isMobile && toggleOpen();
        })
        .catch(() => {
          setIsOpenConfirm(false);
          setIsLoading(false);
        });
    } else {
      addToCartWithNewMerchant(item);
      !isMobile && toggleOpen();
      close();
    }
  }, [
    authUser,
    merchant,
    isMobile,
    item,
    addToCartWithNewMerchant,
    close,
    toggleOpen,
  ]);

  const addItemToApiCart = useCallback(
    (item: Item) => {
      if (authUser) {
        const data = {
          ...item,
          merchant: merchant?.id || (item.merchant as MerchantType).id,
        };
        api
          .post(`/users/${authUser.uid}/cart/add-item`, data)
          .then((response) => {
            setIsLoading(false);
            logAddToCart({
              itemCategory: item.categories,
              itemId: item.itemId,
              itemName: item.name,
              merchantId: item.merchant,
              quantity: item.qty,
            });
            fetchItems();
            close();
            !isMobile && toggleOpen();
          })
          .catch(() => {
            setIsOpenConfirm(false);
            setIsLoading(false);
          });
      } else {
        addToCart(item);
        close();
        !isMobile && toggleOpen();
      }
    },
    [authUser, merchant, isMobile, item, addToCart, close, toggleOpen]
  );

  useEffect(() => {
    if (merchant) {
      item.merchant = merchant;
    } else if (typeof item.merchant === "object") {
      setMerchant(item.merchant);
    }
  }, [merchant]);

  if (isFinding)
    return (
      <>
        <S.LoadWrapper>
          <S.Content hasImage={false}>
            <ContentLoader
              height={130}
              width={300}
              speed={1}
              style={{ marginBottom: 30 }}
              foregroundColor={"#efefef"}
              backgroundColor={"#dfdfdf"}
              viewBox="0 0 300 100"
            >
              <rect x="0" y="0" rx="4" ry="4" width="300" height="100" />
            </ContentLoader>

            <S.Title>
              <ContentLoader
                height={30}
                speed={1}
                foregroundColor={"#efefef"}
                backgroundColor={"#dfdfdf"}
                viewBox="0 0 300 40"
              >
                <rect x="0" y="8" rx="4" ry="4" width="100%" height="25" />
              </ContentLoader>
            </S.Title>
            <S.MerchantName>
              <ContentLoader
                height={30}
                speed={1}
                foregroundColor={"#efefef"}
                backgroundColor={"#dfdfdf"}
                viewBox="0 0 220 40"
              >
                <rect x="0" y="8" rx="4" ry="4" width="100%" height="25" />
              </ContentLoader>
            </S.MerchantName>
            <S.Description>
              <ContentLoader
                height={100}
                width={"60%"}
                speed={1}
                foregroundColor={"#efefef"}
                backgroundColor={"#dfdfdf"}
                viewBox="0 0 380 80"
              >
                <rect x="0" y="8" rx="4" ry="4" width="100%" height="10" />
                <rect x="0" y="28" rx="4" ry="4" width="100%" height="10" />
                <rect x="0" y="48" rx="4" ry="4" width="100%" height="10" />
                <rect x="0" y="68" rx="4" ry="4" width="100%" height="10" />
              </ContentLoader>
            </S.Description>
          </S.Content>
          <S.Footer>
            <InputNumber
              border
              onChangeValue={onChangeQuantity}
              value={qty}
              minValue={1}
            ></InputNumber>
            <Button
              onClick={addItemToCart}
              loading={isLoading}
              disabled={isValidItem() || !merchant}
            >
              {editing ? "Update" : "Add"} {moneyFormatter(total)}
            </Button>
          </S.Footer>
        </S.LoadWrapper>
      </>
    );

  return (
    <>
      <S.Wrapper>
        {item.featuredPicture && item.featuredPicture.length > 0 && (
          <S.ItemImage src={item?.featuredPicture} alt={item?.name} />
        )}
        <S.Content
          hasImage={
            item.featuredPicture !== undefined &&
            item.featuredPicture.length > 0
          }
        >
          <S.Title>{Case.capital(Case.lower(item.name))}</S.Title>
          {!merchant ? (
            <MerchantLoader />
          ) : (
            <S.MerchantName
              onClick={() => {
                close();
                router.push(`/merchants/${merchant.slug}/${merchant.id}`);
              }}
            >
              <p>{Case.capital(Case.lower(merchant.name))}</p>
              <S.RightIcon src="/img/icons-right.svg" />
            </S.MerchantName>
          )}
          <S.Description
            dangerouslySetInnerHTML={{
              __html: replaceText(item.description),
            }}
          />
          <S.ItemPriceWrapper>
            <ItemPrice item={item} />
            {item.campaigns?.length > 0 && (
              <S.DiscountItemTag>
                <img
                  src="/img/icons/one-color/voucher.svg"
                  width={16}
                  height={16}
                />

                <p>
                  Spend{" "}
                  {formatCurrencyWithSymbol(
                    item.campaigns[0].minimumOrderValue / 100
                  )}
                  , save{" "}
                  {formatCurrencyWithSymbol(
                    item.campaigns[0].voucherShift / 100
                  )}
                </p>

                <span />
                <span />
                <span />
              </S.DiscountItemTag>
            )}
          </S.ItemPriceWrapper>
          {item.modifiers !== undefined &&
            item.modifiers.map((modifier, index) => (
              <Modifier
                key={modifier.modifierId}
                options={options}
                modifier={modifier}
                index={index}
                onChangeModifier={onChangeModifier}
              />
            ))}
          <Description
            description={description}
            onChangeDescription={onChangeDescription}
          />
        </S.Content>
        <S.Footer>
          <div>
            {!merchant ? (
              <MerchantLoader />
            ) : (
              !isBentoPackage && (
                <InputNumber
                  border
                  onChangeValue={onChangeQuantity}
                  value={qty}
                  minValue={1}
                />
              )
            )}
          </div>
          <Button
            onClick={addItemToCart}
            loading={isLoading}
            disabled={isValidItem() || !merchant}
          >
            {editing ? "Update" : "Add"} {moneyFormatter(total)}
          </Button>
        </S.Footer>
      </S.Wrapper>

      <Modal widthAuto open={isOpenConfirm} onClose={onClose}>
        <S.Wrapper>
          <S.Container>
            <S.ConfirmTitle>Cart Items</S.ConfirmTitle>
            <S.ConfirmDescription>
              You have items from another place in the Cart. Would like to
              remove them?
            </S.ConfirmDescription>
            <B.ButtonContainer>
              <B.NegativeButton onClick={onClose}>No</B.NegativeButton>
              <Button onClick={handleAddItem} loading={isLoading}>
                Yes
              </Button>
            </B.ButtonContainer>
          </S.Container>
        </S.Wrapper>
      </Modal>
    </>
  );
};

export default ItemDetail;
