import { useState } from "react";
import * as S from "../styles";
import type { Item } from "../interface";

const moneyFormatter = (money: number) => "$" + (money / 100).toFixed(2);

const ItemPrice = ({ item }: { item: Item }) => {
  const discountPrice =
    item.prices?.length > 0 ? Math.min(...item.prices.map((p) => p.price)) : 0;
  const hasDiscountPrice = discountPrice > 0 && discountPrice < item.price;

  if (hasDiscountPrice)
    return (
      <S.ItemPrice>
        <S.ItemPriceDiscount>
          {moneyFormatter(discountPrice)}
        </S.ItemPriceDiscount>
        <S.ItemPriceOverline>{moneyFormatter(item.price)}</S.ItemPriceOverline>
      </S.ItemPrice>
    );

  return item.price > 0 ? (
    <S.ItemPrice>{moneyFormatter(item.price)}</S.ItemPrice>
  ) : (
    <></>
  );
};

export default ItemPrice;
