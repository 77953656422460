import { useRouter } from "next/router";
import {
  createContext,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";

import { CardType } from "components/CardList/interface";
import Cart from "components/Cart";
import {
  AddressType,
  CartType,
  DeliveryFeeType,
  Item,
  MerchantServicesAvailable,
  MerchantType,
} from "components/Cart/interface";
import { BentoSettingsType } from "components/FormCard/interface";
import { MagicVoucherType } from "components/MagicVouchersList/interface";
import { ProfileType } from "components/Profile/interface";
import Toast from "components/Toast";
import { useAnalytics } from "hooks/useAnalytics";
import api from "services/api";
import { useAuth } from "./AuthUserContext";

type DeliveryFeeResponse = {
  fee: number;
  deliveryTime: number;
  distanceMeters: number;
  message?: string;
};

type CartContextProps = {
  cart: CartType;
  addresses: AddressType[];
  setAddresses: Dispatch<SetStateAction<AddressType[]>>;
  isAddressLoading: boolean;
  setHasToFetchFee: Dispatch<SetStateAction<boolean>>;
  cards: CardType[];
  setCards: Dispatch<SetStateAction<CardType[]>>;
  vouchers: MagicVoucherType[];
  setVouchers: Dispatch<SetStateAction<MagicVoucherType[]>>;
  fetchVouchers: () => void;
  isVouchersLoading: boolean;
  profile: ProfileType | null;
  getProfile: () => Promise<void>;
  isProfileLoading: boolean;
  addToCart: (item: Item) => void;
  removeFromCart: (item: Item) => void;
  changeDelivery: (delivery: "TAKEOUT" | "DELIVERY", cart: CartType) => void;
  getTotalItem: (item: Item) => number;
  getTotalVouchers: (magicVouchers: MagicVoucherType[]) => number;
  toggleOpen: () => void;
  setCart: Dispatch<SetStateAction<CartType>>;
  isLoadingCart: boolean;
  addToCartWithNewMerchant: (item: Item) => void;
  fetchItems: () => void;
  handleCompleteOrder: () => void;
  bentoSettings: BentoSettingsType | undefined;
  magicVoucherAnimation: boolean;
  setMagicVoucherAnimation: Dispatch<SetStateAction<boolean>>;
  isRangeError: boolean;
  rangeErrorMessage: string | undefined;
  hasInfoMessage: boolean;
  getItemQuantity: (item: Item) => number;
  getLatestItemQuantity: (item: Item) => number;
  getItemDummyId: (item: Item) => string | undefined;
  removedItem: Item | undefined;
  latestCart: CartType;
  editItem: (item: Item) => void;
};

const initialCart: CartType = {
  items: [],
  deliveryFee: 0,
  magicVouchers: [],
  merchant: null,
  paymentMethod: null,
  total: 0,
  subTotal: 0,
  address: null,
  type: "",
};

export const CartContext = createContext<CartContextProps>(
  {} as CartContextProps
);

type CartProviderProps = {
  children: React.ReactNode;
};

export function CartProvider({ children }: CartProviderProps) {
  const [cart, setCart] = useState<CartType>(initialCart);
  const [latestCart, setLatestCart] = useState<CartType>(initialCart);
  const [addresses, setAddresses] = useState<AddressType[]>([]);
  const [isAddressLoading, setIsAddressLoading] = useState(true);
  const [isRangeError, setIsRangeError] = useState(false);
  const [rangeErrorMessage, setRangeErrorMessage] = useState<string>();
  const [hasInfoMessage, setHasInfoMessage] = useState(false);
  const [cards, setCards] = useState<CardType[]>([]);
  const [vouchers, setVouchers] = useState<MagicVoucherType[]>([]);
  const [isVouchersLoading, setIsVouchersLoading] = useState(true);
  const [profile, setProfile] = useState<ProfileType | null>(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [removedItem, setRemovedItem] = useState<Item>();
  const [magicVoucherAnimation, setMagicVoucherAnimation] = useState(false);
  const [isLoadingCart, setIsLoadingCart] = useState(false);
  const [hasToFetchFee, setHasToFetchFee] = useState(false);
  const { authUser } = useAuth();
  const { logPurchase } = useAnalytics();
  const router = useRouter();
  const bentoPackageId = "aMpH5VC0j6iWA9hRLbdR";
  const [isBentoPackage, setIsBentoPackage] = useState(
    cart.merchant?.id === bentoPackageId
  );
  const servicesAvailable = cart.merchant?.servicesAvailable
    ? new Set(cart.merchant?.servicesAvailable)
    : null;
  const isVoucher = servicesAvailable?.has(MerchantServicesAvailable.voucher);

  const getItemQuantity = (itemProp: Item) => {
    const sumWithInitial = cart.items.reduce((n, { qty, itemId }) => {
      if (itemProp.itemId === itemId) return n + qty;
      return n;
    }, 0);

    return sumWithInitial || 0;
  };

  const getLatestItemQuantity = (itemProp: Item) => {
    const sumWithInitial = latestCart.items.reduce((n, { qty, itemId }) => {
      if (itemProp.itemId === itemId) return n + qty;
      return n;
    }, 0);

    return sumWithInitial || 0;
  };

  const getItemDummyId = (itemProp: Item) => {
    const filteredItems = cart.items.filter((item) => {
      return itemProp.itemId === item.itemId;
    });

    return filteredItems[0]?.itemDummyId;
  };

  const toggleOpen = () => {
    setIsOpen((old) => !old);
  };

  const editItem = (item: Item) => {
    const objIndex = cart.items.findIndex(
      (itemProp) => itemProp.itemId == item.itemId
    );
    const newCart = { ...cart };

    if (objIndex === -1) {
      newCart.items.push(item);
    } else {
      newCart.items = cart.items.map((itemProp) => {
        if (itemProp.itemId === item.itemId) return item;
        return itemProp;
      });
    }

    setIsLoadingCart(true);

    const merchant = item.merchant as MerchantType;
    item.merchant = merchant.id;
    const currentCart: CartType = {
      ...cart,
      items: newCart.items,
      merchant: {
        id: merchant.id,
        name: merchant.name,
        logo: merchant.logo,
        slug: merchant.slug,
        servicesAvailable: merchant.servicesAvailable,
        warningMessage: merchant.warningMessage,
        coordinates: {
          lat: merchant.coordinates?.lat || merchant.address?.coordinates.lat,
          lng: merchant.coordinates?.lng || merchant.address?.coordinates.lng,
        },
      },
    };

    if (isBentoPackage) {
      calculateCart({ ...currentCart, address: null, pickupAddress: null });
    } else {
      calculateCart(currentCart);
    }

    fetchItems();
  };

  const addToCart = useCallback(
    async (item: Item) => {
      addItemToCart(item);
      fetchItems();
    },
    [cart]
  );

  const fetchDeliveryFee = useCallback(async () => {
    let addressFrom;
    setHasInfoMessage(false);
    setIsRangeError(false);
    setIsLoadingCart(true);

    setLatestCart(cart);

    addressFrom = {
      coordinates: {
        lat: cart.merchant?.coordinates?.lat!,
        lng: cart.merchant?.coordinates?.lng!,
      },
    };

    if (isBentoPackage) {
      addressFrom = {
        coordinates: {
          lat: cart.pickupAddress?.coordinates.lat!,
          lng: cart.pickupAddress?.coordinates.lng!,
        },
      };
    }

    let addressTo = {
      coordinates: {
        lat: cart.address?.coordinates.lat!,
        lng: cart.address?.coordinates.lng!,
      },
      coordinatesAdjustment: {
        lat: cart.address?.coordinatesAdjustment.lat || 0,
        lng: cart.address?.coordinatesAdjustment.lng || 0,
      },
    };

    if (!Boolean(addressFrom.coordinates.lat)) {
      setIsLoadingCart(false);
      return;
    }

    try {
      const deliveryData: DeliveryFeeType = {
        addressFrom: addressFrom,
        addressTo: addressTo,
        merchant: {
          id: cart.merchant?.id!,
        },
        user: {
          uuid: authUser?.uid!,
          email: authUser?.email!,
          name: authUser?.name!,
          merchantId: cart.merchant?.id!,
        },
      };

      const { data } = await api.post<DeliveryFeeResponse>(
        `/delivery/fee`,
        deliveryData
      );
      const newCart = { ...cart, deliveryFee: data.fee };

      if (data.message) {
        setRangeErrorMessage(data.message);
        setHasInfoMessage(true);
      }

      setHasToFetchFee(false);
      calculateCart(newCart);
    } catch (error: any) {
      const errorData = error.response.data;
      if (!errorData.inRange) {
        setRangeErrorMessage(errorData.message);
        setIsRangeError(true);
      }
    } finally {
      setIsLoadingCart(false);
    }
  }, [authUser?.uid, cart]);

  const addToCartWithNewMerchant = async (item: Item) => {
    setIsLoadingCart(true);
    setLatestCart(cart);
    const merchant = item.merchant as MerchantType;
    item.merchant = merchant.id;
    const newCart: CartType = {
      ...cart,
      items: [item],
      merchant: {
        id: merchant.id,
        name: merchant.name,
        logo: merchant.logo,
        slug: merchant.slug,
        servicesAvailable: merchant.servicesAvailable,
        warningMessage: merchant.warningMessage,
        coordinates: {
          lat: merchant.coordinates?.lat || merchant.address?.coordinates.lat,
          lng: merchant.coordinates?.lng || merchant.address?.coordinates.lng,
        },
      },
    };

    if (isBentoPackage) {
      calculateCart({ ...newCart, address: null, pickupAddress: null });
    } else {
      calculateCart(newCart);
    }

    fetchItems();
  };

  const addItemToCart = useCallback(
    (item: Item) => {
      const newCart = { ...cart };
      newCart.items.push(item);
      calculateCart(newCart);
    },
    [cart]
  );

  const removeFromCart = useCallback(
    async (item: Item) => {
      if (authUser?.uid) {
        setIsLoadingCart(true);
        setLatestCart(cart);
        setRemovedItem(item);
        try {
          const { data } = await api.post(
            `/users/${authUser?.uid}/cart/remove-item`,
            { itemDummyId: item.itemDummyId }
          );

          fetchItems();
        } catch (error) {}
      } else {
        const newCart = { ...cart };
        newCart.items = newCart.items.filter((i) => i.itemId !== item.itemId);
        calculateCart(newCart);
        if (newCart?.items?.length === 0) setIsOpen(false);
      }
    },
    [cart]
  );

  const getTotalItem = useCallback((item: Item) => {
    const totalModifiers = item.modifiers
      .map((modifier) => {
        return modifier.options.reduce((total, option) => {
          if (option.qty > 0) total += option.price * option.qty;
          return total;
        }, 0);
      })
      .reduce((total, value) => (total += value), 0);

    const discountPrice =
      item.prices?.length > 0
        ? Math.min(...item.prices.map((p) => p.price))
        : 0;
    const hasDiscountPrice = discountPrice > 0 && discountPrice < item.price;
    const newPrice = hasDiscountPrice ? discountPrice : item.price;

    return (newPrice + totalModifiers) * item.qty;
  }, []);

  const getTotalVouchers = useCallback((magicVouchers: MagicVoucherType[]) => {
    return magicVouchers.reduce((total, voucher) => {
      total += voucher.balance;
      return total;
    }, 0);
  }, []);

  const calculateCart = useCallback((cart: CartType) => {
    const newCart = { ...cart };
    newCart.subTotal = Array.isArray(newCart.items)
      ? newCart.items.reduce((total, item) => {
          total += getTotalItem(item);
          return total;
        }, 0)
      : 0;
    const deliveryFee =
      newCart.type === "DELIVERY" ? newCart.deliveryFee || 0 : 0;
    newCart.total = newCart.subTotal + deliveryFee;
    // if (newCart.magicVouchers?.length > 0) {
    //   newCart.total -= getTotalVouchers(newCart.magicVouchers);
    // }

    const isBentoPackageMerchant =
      newCart.merchant?.id === "aMpH5VC0j6iWA9hRLbdR";

    setIsBentoPackage(isBentoPackageMerchant);

    if (isBentoPackageMerchant) newCart.type = "DELIVERY";

    setCart(newCart);

    if (!authUser) setIsLoadingCart(false);
  }, []);

  const changeDelivery = useCallback(
    (delivery: "TAKEOUT" | "DELIVERY", cart: CartType) => {
      cart.type = delivery;
      calculateCart(cart);
    },
    []
  );

  const getProfile = useCallback(async () => {
    if (authUser?.uid) {
      try {
        setIsProfileLoading(true);
        setIsLoadingCart(true);
        setLatestCart(cart);

        const { data } = await api.get<ProfileType>(
          `/users/${authUser?.uid}/profile`
        );
        setProfile(data);
      } finally {
        setIsProfileLoading(false);
        setIsLoadingCart(false);
      }
    }
  }, [authUser]);

  const setCartToLocalStorage = useCallback((cart: CartType) => {
    localStorage.setItem("cart", JSON.stringify(cart));
  }, []);

  const resetCartFromLocalStorage = () => {
    localStorage.removeItem("cart");
  };

  const getCartFromLocalStorage = (): CartType => {
    const cartStorage = localStorage.getItem("cart");
    return cartStorage
      ? (JSON.parse(cartStorage) as CartType)
      : ({} as CartType);
  };

  const findDefaultAddress = (
    addresses: AddressType[],
    isBentoPackage: boolean
  ) => {
    if (isBentoPackage) return null;
    return addresses.find((address) => address.isDefault) || addresses[0];
  };

  const findDefaultCard = (cards: CardType[]) => {
    return cards.find((card) => card.defaultMethod) || cards[0];
  };

  useEffect(() => {
    if (authUser?.uid) {
      cart.isOffline = false;
      setCartToLocalStorage(cart);
    } else {
      cart.isOffline = true;
      setCartToLocalStorage(cart);
    }
  }, [cart]);

  useEffect(() => {
    fetchItems();
    fetchBentoSettings();
  }, []);

  useEffect(() => {
    const isBentoPackage = cart.merchant?.id === bentoPackageId;
    if (isBentoPackage) {
      setCart((old) => ({
        ...old,
        type: "DELIVERY",
        address: null,
        pickupAddress: null,
      }));
    }
  }, [cart.merchant]);

  useEffect(() => {
    fetchItems();
  }, [authUser]);

  const fetchVouchers = async () => {
    setIsVouchersLoading(true);

    const { data: vouchersData } = await api.get<MagicVoucherType[]>(
      `/users/${authUser?.uid}/vouchers`
    );
    setVouchers(vouchersData);
    setIsVouchersLoading(false);
  };

  const fetchItems = useCallback(async () => {
    if (authUser?.uid) {
      setIsLoadingCart(true);
      setLatestCart(cart);

      let newCart = initialCart;
      try {
        const storageCart = getCartFromLocalStorage();
        if (
          storageCart &&
          storageCart.isOffline &&
          storageCart?.items?.length > 0
        ) {
          const { data } = await api.post(
            `/users/${authUser?.uid}/cart/persist-cart`,
            storageCart,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          newCart = data;
        } else {
          const { data } = await api.get(`/users/${authUser?.uid}/cart`);

          const lastUpdate = data.lastUpdateAt;
          const now = new Date().getTime();
          const diffHours = Math.abs(lastUpdate - now) / 36e5;

          if (data)
            newCart = { ...cart, merchant: data.merchant, items: data.items };

          if (diffHours >= 6) {
            await api.delete(`/users/${authUser?.uid}/cart/delete`);

            Toast({
              title: "Error",
              message: "Cart invalidated due to inactivity 🙃",
            });

            setIsLoadingCart(false);
            return;
          }
        }

        const isBentoPackageMerchant =
          newCart.merchant?.id === "aMpH5VC0j6iWA9hRLbdR";

        if (!addresses?.length) {
          const {
            data: { addresses: addressesData },
          } = await api.get<{ addresses: AddressType[] }>(
            `/users/${authUser?.uid}/addresses`
          );
          setAddresses(addressesData);

          newCart.address = findDefaultAddress(
            addressesData,
            isBentoPackageMerchant
          );
          setIsAddressLoading(false);
        } else {
          newCart.address = newCart.address
            ? newCart.address
            : findDefaultAddress(addresses, isBentoPackageMerchant);
          setIsAddressLoading(false);
        }

        if (!cards?.length) {
          const { data: cardData } = await api.get<CardType[]>(
            `/users/${authUser?.uid}/payments`
          );
          setCards(cardData);
          newCart.paymentMethod = findDefaultCard(cardData);
        } else {
          newCart.paymentMethod = newCart.paymentMethod
            ? newCart.paymentMethod
            : findDefaultCard(cards);
        }

        const { data: vouchersData } = await api.get<MagicVoucherType[]>(
          `/users/${authUser?.uid}/vouchers`
        );
        setVouchers(vouchersData);
        setIsVouchersLoading(false);

        await getProfile();

        if (isBentoPackage) newCart.type = "DELIVERY";

        calculateCart(newCart);
        newCart?.items?.length > 0 && setHasToFetchFee(true);
      } finally {
        setRemovedItem(undefined);
        setIsLoadingCart(false);
      }
    }
  }, [authUser?.uid, addresses, cards, cart]);

  const clearCart = () => {
    resetCartFromLocalStorage();
    setCart(initialCart);
  };

  const handleCompleteOrder = useCallback(async () => {
    const hasPaymentMethod = !!cart.paymentMethod;
    if (!hasPaymentMethod) {
      return;
    }

    if (cart.total < 0) {
      Toast({
        title: "Error",
        message: "Order total must be greater than Zero",
      });
      return;
    }

    setIsLoadingCart(true);
    setLatestCart(cart);

    const data = {
      ...cart,
      user: {
        uuid: authUser?.uid,
        email: authUser?.email,
        name: authUser?.name,
      },
      type: isVoucher ? "TAKEOUT" : cart.type,
      items: cart.items.map((item) => {
        const discountPrice =
          item.prices?.length > 0
            ? Math.min(...item.prices.map((p) => p.price))
            : 0;
        const hasDiscountPrice =
          discountPrice > 0 && discountPrice < item.price;
        const newPrice = hasDiscountPrice ? discountPrice : item.price;

        return {
          ...item,
          price: newPrice,
        };
      }),
    };
    delete data.isOffline;

    try {
      const resp = await api.post(`/orders`, data);
      clearCart();
      toggleOpen();
      logPurchase({
        origin: data.merchant?.id || "",
        value: data.subTotal / 100,
        transactionId: resp.data?.shortId,
      });
      Toast({
        type: "success",
        title: "Order created 🎉",
        message: (
          <>
            Order <strong>{resp.data.shortId}</strong> successfully placed
          </>
        ),
      });
      router.push("/orders");
    } finally {
      setIsLoadingCart(false);
    }
  }, [cart, authUser]);

  const [bentoSettings, setBentoSettings] = useState<BentoSettingsType>();

  const fetchBentoSettings = useCallback(async () => {
    setIsLoadingCart(true);
    try {
      const { data } = await api.get(`/bento-settings`);
      setBentoSettings(data);
    } finally {
      setIsLoadingCart(false);
    }
  }, []);

  useEffect(() => {
    if (
      authUser &&
      cart.address &&
      cart.merchant &&
      cart.type === "DELIVERY" &&
      hasToFetchFee
    ) {
      setIsLoadingCart(true);
      fetchDeliveryFee();
    }
  }, [authUser, cart, hasToFetchFee]);

  useEffect(() => {
    if (!authUser) {
      let newCart = { ...cart };
      setAddresses([]);
      setCards([]);
      newCart.address = null;
      newCart.paymentMethod = null;
      newCart.deliveryFee = 0;
      calculateCart(newCart);
    }
  }, [authUser]);

  useEffect(() => {
    let newCart = { ...cart };
    calculateCart(newCart);
  }, [cart.magicVouchers]);

  return (
    <CartContext.Provider
      value={{
        cart,
        toggleOpen,
        addToCart,
        changeDelivery,
        removeFromCart,
        getTotalItem,
        getTotalVouchers,
        setCart,
        isLoadingCart,
        addToCartWithNewMerchant,
        addresses,
        setAddresses,
        isAddressLoading,
        setHasToFetchFee,
        cards,
        setCards,
        vouchers,
        setVouchers,
        fetchVouchers,
        isVouchersLoading,
        profile,
        getProfile,
        isProfileLoading,
        fetchItems,
        handleCompleteOrder,
        bentoSettings,
        magicVoucherAnimation,
        setMagicVoucherAnimation,
        isRangeError,
        rangeErrorMessage,
        hasInfoMessage,
        getItemQuantity,
        getItemDummyId,
        removedItem,
        latestCart,
        getLatestItemQuantity,
        editItem,
      }}
    >
      {children}
      <Cart aria-hidden={!isOpen} onClose={toggleOpen} isOpen={isOpen} />
    </CartContext.Provider>
  );
}
